/**
 * @see https://github.com/codrops/ThumbHoverSVGFilter/blob/main/src/js/cursor.js
 */ 
import { lerp, getMousePos } from './utils';
import close from '../images/close.svg';
import Scrollbar from 'smooth-scrollbar';

// Track the mouse position
let mouse = {x: 0, y: 0};
document.getElementById("root").addEventListener('mousemove', ev => {
    mouse = getMousePos(ev)
});

export default class Cursor {
    constructor(el) {
        this.DOM = {el: el};
        this.DOM.el.style.opacity = 0;

        this.offsetY = Scrollbar.get(document.body)?.offset.y || 0; //parseInt(getComputedStyle(document.documentElement).getPropertyValue('--offset-y'));
        
        this.bounds = this.DOM.el.getBoundingClientRect();
        
        this.renderedStyles = {
            tx: {previous: 0, current: 0, amt: 0.2},
            ty: {previous: 0, current: 0, amt: 0.2},
            scale: {previous: 1, current: 1, amt: 0.2},
            opacity: {previous: 0, current: 0.8, amt: 0.5},
        };

        this.onMouseMoveEv = () => {
            this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds.width/2;
            this.renderedStyles.ty.previous = this.renderedStyles.ty.previous = mouse.y - this.bounds.height/2 + this.offsetY;
            this.DOM.el.style.opacity = 1;
            requestAnimationFrame(() => this.render());
            document.getElementById("root").removeEventListener('mousemove', this.onMouseMoveEv);
        };
        this.onBodyScroll = (status) => {
            let offsetY = status.offset.y;
            this.offsetY = offsetY;
        }
        document.getElementById("root").addEventListener('mousemove', this.onMouseMoveEv);
        Scrollbar.get(document.body)?.addListener(this.onBodyScroll);
    }
    enter() {
        this.renderedStyles['scale'].current = 3;
        this.renderedStyles['opacity'].current = 0.8;
    }
    leave() {
        this.renderedStyles['scale'].current = 1;
        this.renderedStyles['opacity'].current = 0.8;
    }
    enterFullscreen() {
        this.DOM.el.style.backgroundImage = 'url('+close+')';
        this.DOM.el.classList.add('cursor-close');
        this.renderedStyles['opacity'].current = 1;
    }
    leaveFullscreen() {
        this.DOM.el.style.backgroundImage = '';
        this.DOM.el.classList.remove('cursor-close');
        this.renderedStyles['opacity'].current = 0;
    }
    render() {
        this.renderedStyles['tx'].current = mouse.x - this.bounds.width/2;
        this.renderedStyles['ty'].current = mouse.y - this.bounds.height/2 + this.offsetY;

        for (const key in this.renderedStyles ) {
            this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
        }
                    
        this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px) scale(${this.renderedStyles['scale'].previous})`;
        this.DOM.el.style.opacity = this.renderedStyles['opacity'].previous;

        requestAnimationFrame(() => this.render());
    }
}