import React, { useEffect, forwardRef, useLayoutEffect } from "react";
import { ScrollbarPlugin } from 'smooth-scrollbar';

import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
//import "@fancyapps/ui/dist/fancybox.css";
/**
 * fallback for requestAnimationFrame & cancelAnimationFrame
 */
 (function() {
	var lastTime = 0;
	var vendors = ['ms', 'moz', 'webkit', 'o'];
	for(var x = 0; x > vendors.length && !window.requestAnimationFrame; ++x) {
	  window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
	  window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
								 || window[vendors[x]+'CancelRequestAnimationFrame'];
	}
  
	if (!window.requestAnimationFrame)
	  window.requestAnimationFrame = function(callback, element) {
		var currTime = new Date().getTime();
		var timeToCall = Math.max(0, 16 - (currTime - lastTime));
		var id = window.setTimeout(function() { callback(currTime + timeToCall); },
		  timeToCall);
		lastTime = currTime + timeToCall;
		return id;
	  };
  
	if (!window.cancelAnimationFrame)
	  window.cancelAnimationFrame = function(id) {
		clearTimeout(id);
	  };
}());

/**
 * If using animation is not blocked by system
 */
const {matches:motionOK} = window.matchMedia(
	'(prefers-reduced-motion: no-preference)'
)

/**
 * Set the real VW and VH variables to the :root
 */
const setVwVh = () => {
	let vw = document.documentElement.clientWidth / 100;
	let vh = document.documentElement.clientHeight / 100;
	document.documentElement.style.setProperty('--vw', `${vw}px`);
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

/**
 * Strip HTML from string
 * 
 * @see https://stackoverflow.com/a/822486/2573521
 * @param {string} html 
 * @returns string
 */
const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

/**
 * Calculating progress for the animation
 * @param {integer} time current time of the animation
 * @param {integer} start  start time of the animation
 * @param {integer} duration duration of the animation
 * @returns {float} animation progress from 0 till 1
 */
const calcProgress = (time, start, duration) => {
	return ((time - start) / duration).toFixed(3); 
}

/**
 * Split node's inner html into letters and elements
 * 
 * @param Node element's node 
 * @returns array of letters and html elements of the node
 */
const textToLetters = (node) => {
	let textArr = Array.from(node.childNodes);
	let letters = [];

	textArr.forEach( (el) => {
		
		if (el.nodeType === 3) { // simple text (without html)
			letters = letters.concat(el.textContent.split(''));
			
		} else { // html element
			if (el.textContent) { // if has text
				let newArr = el.textContent.split('').map((currentValue, index) => {
					let newEl = el.cloneNode(true);
					newEl.innerHTML = currentValue;
					return newEl.outerHTML;
				})
				letters = letters.concat(...newArr);
			} else {
				letters = letters.concat(el.outerHTML);
			}
		}
	})

	return letters;
}

/**
 * Split words so we can animate each letter
 * @see https://web.dev/building-split-text-animations/
 */
 const spanText = (text, index, letters = false) => {
	if (!text) return '';
	
	const node = document.createElement('span')
	
	node.className = 'animation-item';
	node.style.setProperty('--index', index)

	if (letters) {
		if (stripHtml(text) === ' ') { // detect space
			//console.log(text, 'space')
			node.innerHTML = stripHtml(text);
			node.className += ' animation-item_space';
		} else if ( text.includes('<br>') ) { // detect break
			//console.log(text, 'break')
			node.innerHTML = '<br>';
			node.className += ' animation-item_break';
		} else {
			//console.log(text, 'letter')
			node.innerHTML = text;
			node.className += ' animation-item_letter';
		}
	} else {
		if ( text.includes('<br>') ) { // detect break
			//console.log(text, 'break')
			node.innerHTML = '<br>';
			node.className += ' animation-item_break';
		} else {
			node.innerHTML = text;
		}
	}
	
	return node
}

/**
 * Transform text into circle
 * @param {HTMLElement} textBlock
 */
const circulizeText = (textBlock, speed = 5, size = 10) => {
	if (!motionOK) return true;

	let text = textBlock.querySelector('p');
	//let originalText = textBlock.getAttribute('data-text');
	let textRotatingSpeed = speed; // 1-10 - bigger => faster
	let circleSize = size; // 1-20em

	//text.innerHTML = originalText;

	let letters = textToLetters(text).filter((el) => {
		return el !== null && el !== "";
	});
	let nodes = letters.map((value, index) => {
		return spanText(value, index, true);
	});

	let lettersCount = letters.length;

	text.style.setProperty('--angle', (360 / (lettersCount + +1)));
	text.style.setProperty('--speed', textRotatingSpeed);
	text.style.setProperty('--size', circleSize);

	if (textRotatingSpeed === '0') {
		text.classList.add('no-rotate');
	}

	if (nodes) {
		while (text.childNodes.length > 1) {
			text.removeChild(text.lastChild);
		}
		text.firstChild.replaceWith(...nodes);

		let newHTML = text.innerHTML;
		
		text.innerHTML = newHTML;
	}
}

/**
 * Explode string into letters wrapped by <spans>
 * @param {HTMLElement} element 
 */
const explodeText = (element) => {
	let words = [];
    let nodes = null;

	words = textToLetters(element).filter((el) => {
		return el !== null && el !== "";
	});
	nodes = words.map((value, index) => {
		return spanText(value, index, true);
	});
	if (nodes) {
		element.firstChild.replaceWith(...nodes);

		let newHTML = element.innerHTML;
		
		newHTML = newHTML.replace(/<span class="animation-item animation-item_space"[^>]*> <\/span>/g, '$&</span>');
		newHTML = newHTML.replace(/<span class="animation-item animation-item_space"[^>]*> <\/span><\/span>/g, '$&<span class="word">');
		newHTML = '<span class="word">' + newHTML + '</span>';
		newHTML = newHTML.replace(/<span class="animation-item animation-item_break"[^>]*><br><\/span>/g, '</span>$&<span class="word">');
		
		element.innerHTML = newHTML;
	}
}

/**
 * Gets computed translate values
 * @param {HTMLElement} element
 * @returns {Object}
 * @see https://zellwk.com/blog/css-translate-values-in-javascript/
 */
function getTranslateValues (element) {
	const style = window.getComputedStyle(element)
	const matrix = style['transform'] || style.webkitTransform || style.mozTransform
  
	// No transform property. Simply return 0 values.
	if (matrix === 'none' || typeof matrix === 'undefined') {
	  return {
		x: 0,
		y: 0,
		z: 0
	  }
	}
  
	// Can either be 2d or 3d transform
	const matrixType = matrix.includes('3d') ? '3d' : '2d'
	const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')
  
	// 2d matrices have 6 values
	// Last 2 values are X and Y.
	// 2d matrices does not have Z value.
	if (matrixType === '2d') {
	  return {
		x: matrixValues[4],
		y: matrixValues[5],
		z: 0
	  }
	}
  
	// 3d matrices have 16 values
	// The 13th, 14th, and 15th values are X, Y, and Z
	if (matrixType === '3d') {
	  return {
		x: matrixValues[12],
		y: matrixValues[13],
		z: matrixValues[14]
	  }
	}
}


/**
 * Animating element
 * @param {HTMLElement} element - an element to animate
 * @param {integer} animationSpeed - speed of the animation in ms
 */
const animateElement = (element, animationSpeed, animationRule, delay = 0) => {
	let animateID;
	let timeoutID;
	let progress = 0;
	let start = performance.now();
	let duration = animationSpeed; // animation speed in ms.

	const handleAnimation = (time) => {
		time = time - delay;

		progress = calcProgress(time, start, duration);

		if (progress >= 1) {
			progress = 1;
		}

		animationRule(element, progress);

		if (progress >= 1) {
			cancelAnimationFrame(animateID);
			clearTimeout(timeoutID)
			return true;
		}
		
		animateID = requestAnimationFrame(handleAnimation);
	}

	timeoutID = setTimeout(() => {		
		animateID = requestAnimationFrame(handleAnimation);
	}, delay);
	
}

/**
 * Detect when element becomes wrapped in flex mode
 * https://codepen.io/brettdewoody/pen/rrKpPp 
 * 
 * @param string elements - name of the element
 * @returns {array} Array of elements that were wrapped
 */
const detectWrap = (elements) => {
	let wrappedItems = [];
	let prevItem = {};
	let currItem = {};
	let items = typeof elements === 'string' ? document.querySelectorAll(elements) : elements;

	for (let i = 0; i < items.length; i++) {
		currItem = items[i].getBoundingClientRect();
		
		if ( prevItem ) {
			let prevItemTop = prevItem.top;
			let currItemTop = currItem.top;
			
			if ( prevItemTop < currItemTop ) {
				wrappedItems.push(items[i]);
			}	
		}

		prevItem = currItem;
	};
	
	return wrappedItems;
}

function Fancybox(props) {
	const delegate = props.delegate || "[data-fancybox]";
  
	useEffect(() => {
	  const opts = props.options || {};
  
	  NativeFancybox.bind(delegate, opts);
  
	  return () => {
		NativeFancybox.destroy();
	  };
	}, []);
  
	return <>{props.children}</>;
}

// https://shaquillegalimba.medium.com/how-to-import-multiple-images-in-react-1936efeeae7b
function importAll(r) {
	let images = {};
	r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
	return images
}



class AnchorPlugin extends ScrollbarPlugin {
  static pluginName = 'anchor';

  onHashChange = () => {
    this.jumpToHash(window.location.hash);
  };

  onClick = (event) => {
    const { target } = event;

    if (target.tagName !== 'A') {
      return;
    }

    const hash = target.getAttribute('href');

    if (!hash || hash.charAt(0) !== '#') {
      return;
    }

    this.jumpToHash(hash);
  };

  jumpToHash = (hash) => {
    const { scrollbar } = this;

    if (!hash) {
      return;
    }    

    // reset scrollTop
    scrollbar.containerEl.scrollTop = 0;

    scrollbar.scrollIntoView(document.querySelector(hash));
  };

  onInit() {
    this.jumpToHash(window.location.hash);

    window.addEventListener('hashchange', this.onHashChange);

    this.scrollbar.contentEl.addEventListener('click', this.onClick);
  }

  onDestory() {
    window.removeEventListener('hashchange', this.onHashChange);

    this.scrollbar.contentEl.removeEventListener('click', this.onClick);
  }
}

/**
 * cross browser hash change event dispatch
 */
function dispatchHashchange() {
	if (typeof HashChangeEvent !== "undefined") {
		window.dispatchEvent(new HashChangeEvent("hashchange"));
		return;
	}
  
	// HashChangeEvent is not available on all browsers. Use the plain Event.
	try {
		window.dispatchEvent(new Event("hashchange"));
		return;
	} catch (error) {
		// but that fails on ie
	}
  
	// IE workaround
	const ieEvent = document.createEvent("Event");
	ieEvent.initEvent("hashchange", true, true);
	window.dispatchEvent(ieEvent);
}

/**
 * Checks if user's device has pointer (mouse)
 * @returns {boolean}
 */
const isTouchDevice = () => {
	return window.matchMedia('(hover: none)').matches;
}

export { textToLetters, spanText, circulizeText, explodeText, animateElement, detectWrap, getTranslateValues, setVwVh, Fancybox, importAll, AnchorPlugin, dispatchHashchange, isTouchDevice };