import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Scrollbar from 'smooth-scrollbar';
import { explodeText, detectWrap, setVwVh, isTouchDevice } from './core.js';
import { FullscreenMenu, TopMenu, HomeScreen, History, Contact, Footer, Sources, AppContainer } from './blocks.js';
import { Gallery } from './gallery.js';
import Cursor from './cursor';
import lazySizes from 'lazysizes';

import 'normalize.css';
import "@fancyapps/ui/dist/fancybox.css";
import '../less/App.less';


lazySizes.cfg.expand = window.innerHeight * 50;

function debounce(fn, ms) {
  let timer
  return _ => {
    clearTimeout(timer)
    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


/**
 * Init custom cursor
 * @param {*} props 
 * @returns 
 */
function CustomCursor(props) {
  const ref = useRef(null);
  const { appRef } = props;

  const initCursor = () => {
    const cursorEl = ref.current;
    const appContainer = appRef.current;

    if (cursorEl) {
      const cursor = new Cursor(cursorEl);

      // mouse effects on all years
      [...appContainer.querySelectorAll('.title-block')].forEach(element => {
        element.addEventListener('mouseenter', () => cursor.enter());
        element.addEventListener('mouseleave', () => cursor.leave());
      });

      // mouse effects on blurred fullscreen background
      appContainer.querySelector('.fullscreen__bg').addEventListener('mouseenter', () => cursor.enterFullscreen());
      appContainer.querySelector('.fullscreen__bg').addEventListener('mouseleave', () => {
        cursor.leaveFullscreen();
        cursor.leave();
      });
    }
  }

  useEffect(() => {
    //console.log('Custom CUrsor');
    initCursor();
  }, [])

  return (
    <div id="custom-cursor" ref={ref}></div>
  )
}

/**
 * Clear wrapped items
 */
function clearWrappedItems() {
  let wrappedItems = document.querySelectorAll('.was-wrapped');

  wrappedItems.forEach(wrappedItem => {
    wrappedItem.classList.remove("was-wrapped");
    [...wrappedItem.querySelectorAll(".image")].forEach(element => {
      element.style.transform = '';
    });
  });
}

function onResizeFunctions() {
  setVwVh();
  clearWrappedItems();
  const smoothScroll = Scrollbar.get(document.body);

  let header = document.querySelector(".site-header");
  let headerHeight = header ? header.offsetHeight : '0';

  // detect wrapped elements
  let wrappedItemsHomescreen = detectWrap('.homescreen__col');

  let imagesWithText = document.querySelectorAll('.image-with-text');
  let galleries = document.querySelectorAll('.gallery.gallery_cols_2');

  // add wrapped classes if wrapped items were found
  if (wrappedItemsHomescreen.length > 0) {
    wrappedItemsHomescreen.forEach(wrappedItem => {
      wrappedItem.closest('.homescreen').classList.add("was-wrapped");
    });
  }

  if (imagesWithText.length > 0) {
    imagesWithText.forEach(imageWithText => {
      let wrappedItemsImagesWithText = detectWrap(imageWithText.children);

      if (wrappedItemsImagesWithText.length > 0) {
        wrappedItemsImagesWithText.forEach(wrappedItem => {
          wrappedItem.closest('.image-with-text').classList.add("was-wrapped");
        });
      }
    });
  }

  if (galleries.length > 0) {
    galleries.forEach(gallery => {
      let wrappedItemsGalleries = detectWrap(gallery.querySelectorAll('figure:nth-child(1), figure:nth-child(2)'));

      if (wrappedItemsGalleries.length > 0) {
        wrappedItemsGalleries.forEach(wrappedItem => {
          wrappedItem.closest('.gallery').classList.add("was-wrapped");
        });
      }
    });
  }

  document.documentElement.style.setProperty('--header-height', headerHeight + 'px');

  smoothScroll?.update();
}

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })
  
  const [isOrientChange, setIsOrientChange] = useState(false);

  const scrollRef = useRef(null);
  const appRef = useRef(null);

  /**
   * Lazyload images
   * https://codepen.io/GreenSock/pen/gOPEEzY 
   */
  const handleLazyLoad = (event) => {
    const forceRefreshElements = [
      '.animate-image-block',
      //'.history-block_6'
    ]

    if (event.target.closest(forceRefreshElements.join(", "))) {
      ScrollTrigger.refresh();
    }
  }

  useLayoutEffect(() => {
    console.log("CTX")

    let ctx = gsap.context(() => {
      const scroller = document.body; //scrollRef.current; //document.querySelector(".scrollbar-container"); ;
      const fullscreen = scrollRef.current.querySelector(".fullscreen");

      /**
       * Init smooth scroll
       */
      if (!isTouchDevice()) {
        document.documentElement.className = 'has-smoothbar';

        const smoothScroll = Scrollbar.init(scroller, {
          damping: 0.1,
          delegateTo: document, // scrollRef.current,
          continuousScrolling: false,
          alwaysShowTracks: true,
          plugins: {}
        })

        ScrollTrigger.scrollerProxy(scroller, {
          scrollTop(value) {
            if (arguments.length) {
              smoothScroll.scrollTop = value;
            }
            return smoothScroll.scrollTop
          },
          getBoundingClientRect() {
            return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
          },
        })

        smoothScroll.addListener(({ offset }) => {
          ScrollTrigger.update();
          gsap.set(fullscreen, {
            top: offset.y + 'px'
          })

          if (document.querySelector(".fancybox__container")) {
            gsap.set(".fancybox__container", {
              top: offset.y + 'px'
            })
          }
        });

      }

      ScrollTrigger.config({
        ignoreMobileResize: true,
        autoRefreshEvents: "visibilitychange,DOMContentLoaded,load" // remove "resize" event
      });

      ScrollTrigger.defaults({
        preventOverlaps: true,
        fastScrollEnd: false,
        pinType: isTouchDevice() ? 'fixed' : 'transform',
        scroller: isTouchDevice() ? window : document.body,
      });

      onResizeFunctions();

      // Animate preloader
      const preloader = document.querySelector('.preloader');
      preloader.classList.add('preloader_hide');

      gsap.to(preloader, {
        clipPath: "circle(0%)",
        duration: 0.3
      })
      gsap.to(preloader.querySelector("svg"), {
        scale: 0,
        duration: 0.3
      },
        "<"
      )

      document.addEventListener('lazyloaded', handleLazyLoad)

    }, appRef)

    return () => {
      ctx.revert();
      document.removeEventListener('lazyloaded', handleLazyLoad)
    }

  }, []);

  /**
   * Refresh ScrollTrigger on resize (only WIDTH)
   */
  /*useEffect(() => {
    ScrollTrigger.refresh();
  }, [dimensions.width])*/

  useEffect(() => {
    ScrollTrigger.refresh();
    console.log('isOrientChange')
  }, [isOrientChange])

  /**
   * Change background color on scroll
   */
  useEffect(() => {
    console.log('Body BG');

    let ctx = gsap.context(() => {

      let links = scrollRef.current.querySelectorAll(".menu_full .menu__link");

      for (let i = 0; i < links.length; i += 1) {
        explodeText(links[i]);
      }

      //COLOR CHANGER
      const burger = scrollRef.current.querySelector(".burger");
      const scrollColorElems = scrollRef.current.querySelectorAll("[data-bgcolor]");
      let themeColor = scrollRef.current.querySelector('[name=theme-color]');

      scrollColorElems.forEach((colorSection, i) => {
        const prevBg = i === 0 ? "" : scrollColorElems[i - 1].dataset.bgcolor;

        const onEnter = () => {
          gsap.to("body", {
            background: colorSection.dataset.bgcolor,
            overwrite: true, //"auto",
            onStart: function () {
              if (colorSection.dataset.bgcolor !== '#66A6FF' && colorSection.dataset.bgcolor !== '#2482d9') {
                burger.classList.add('burger_white');
              } else {
                burger.classList.remove('burger_white');
              }
            }
          })

          // animate mobile nav bar's color
          if (themeColor)
            themeColor.remove();

          themeColor = document.createElement('meta');
          themeColor.name = 'theme-color';
          themeColor.setAttribute('content', colorSection.dataset.bgcolor);

          document.head.appendChild(themeColor);
        }

        const onLeaveBack = () => {
          gsap.to("body", {
            backgroundColor: prevBg,
            overwrite: true, //"auto",
            onStart: function () {
              if (prevBg !== '#66A6FF') {
                burger.classList.add('burger_white');
              } else {
                burger.classList.remove('burger_white');
              }
            }
          })

          // animate mobile nav bar's color
          if (themeColor)
            themeColor.remove();

          themeColor = document.createElement('meta');
          themeColor.name = 'theme-color';
          themeColor.setAttribute('content', prevBg);

          document.head.appendChild(themeColor);
        }

        ScrollTrigger.create({
          trigger: colorSection,
          start: "top 60%",
          onEnter: () => {
            onEnter();
          },
          onUpdate: () => {
            onEnter();
          },
          onLeaveBack: () => {
            onLeaveBack();
          }
        });

      });

    }, scrollRef)

    return () => ctx.revert();


  }, [])

  // https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
      onResizeFunctions();
      console.log(dimensions.width + 'x' + dimensions.height)
    },
      500, //66
    )

    const handleOrientationChange = () => {
      setIsOrientChange(!isOrientChange);
      console.log('orientationchange')
    }

    window.addEventListener('resize', debouncedHandleResize)
    window.addEventListener('orientationchange', handleOrientationChange)

    return _ => {
      window.removeEventListener('resize', debouncedHandleResize)
      window.removeEventListener('orientationchange', handleOrientationChange)
    }
  })

  useEffect(() => {
    console.log('hash')
    // For newly opened link (e.g in new tab)
    const hash = window.location.hash;
    const smoothScroll = Scrollbar.get(document.body);

    if (hash && smoothScroll) {
      const target = document.getElementById(hash.substring(1));
      if (target) {
        window.onload = function () {
          smoothScroll.scrollIntoView(target, {
            offsetTop: -smoothScroll.containerEl.scrollTop,
          });
        }
      }
    }

    if (hash && !smoothScroll) {
      gsap.to(window, {
        scrollTo: {
          y: document.querySelector(hash).offsetTop
        },
        duration: 0.4,
        onComplete: () => {
          ScrollTrigger.refresh();
        }
      })
    }

    const hashchange = () => {
      const hash = window.location.hash;
      if (hash && smoothScroll) {
        const target = document.getElementById(hash.substring(1));
        if (target) {
          smoothScroll.scrollIntoView(target, {
            offsetTop: -smoothScroll.containerEl.scrollTop,
          });
        }
      }
    }

    // For opening link in the same page
    window.addEventListener('hashchange', hashchange, false);

    return _ => {
      window.removeEventListener('hashchange', hashchange)
    }

  }, [])

  return (
    <AppContainer ref={appRef}>
      <CustomCursor appRef={appRef} />
      <div className="site" ref={scrollRef}>
        <header className="site-header">
          <FullscreenMenu appRef={appRef} />
          <TopMenu appRef={appRef} />
        </header>
        <main className="site-content">
          <div className="container">
            <HomeScreen appRef={appRef} />
            <History appRef={appRef} />
            <Gallery appRef={appRef} />
            <Contact appRef={appRef} />
            <Sources appRef={appRef} />
          </div>
        </main>
        <footer className="site-footer">
          <Footer appRef={appRef} />
        </footer>
      </div>
    </AppContainer>
  );
}

export default App;
