import React, { useState, useEffect, useRef, forwardRef } from 'react';
import axios from 'axios';
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { circulizeText, explodeText, dispatchHashchange, isTouchDevice } from './core.js';

import * as Constants from './constants.js'

// don't forget to register plugins
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

/**
 * Wrapper for the whole app
 */
const AppContainer = forwardRef((props, appRef) => {
  return <div className="app-container" ref={appRef}>{props.children}</div>;
});

function AnimateBlock(props) {

  function handleContentScroll(event) {
    let content = event.target;
    let wrapper = content.closest('.animate-image-block');
    let moveIcon = wrapper?.querySelector('.move-icon');
    let contentScrollWidth = content?.scrollWidth - wrapper?.offsetWidth;
    let currentScroll = event.target.scrollLeft / (contentScrollWidth);
    moveIcon.style.opacity = 1 - currentScroll;
  };

  return <div className='animate-image-block'>
    <div className="move-icon"><img className="lazyload" data-src={Constants.move_icon} alt="move_icon" /></div>
    <div className='animate-image-block__content' onScroll={handleContentScroll}>
      {props.children}
    </div>
  </div>;
}

function Hint(props) {
  const [hintShowed, setHintShowed] = useState(false);

  // https://greensock.com/forums/topic/25988-magnetic-button-with-gsap/
  const parallaxIt = (e, movement) => {
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    let scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
    let element = e.target;
    let relX = e.pageX - scrollLeft - element.getBoundingClientRect().left;
    let relY = e.pageY - scrollTop - element.getBoundingClientRect().top;

    gsap.to(element.querySelector('.hint-block'), {
      duration: 0.3,
      x: (relX - element.offsetWidth / 2) / element.offsetWidth * movement,
      y: (relY - element.offsetHeight / 2) / element.offsetHeight * movement,
      ease: "Power2.easeOut"
    });
  }

  /**
   * Handles hint's hover
   */
  const handleHintHover = event => {
    setHintShowed(oldValue => true);
  }

  /**
   * Handles hint's unhover
   */
  const handleHintUnhover = event => {
    setHintShowed(oldValue => false);
  }

  /**
   * Handles hint's mouse move
   */
  const handleHintMove = (event) => {
    parallaxIt(event, 80);
  }

  return (
    <span className="hint" data-hint={props.hint} onMouseEnter={handleHintHover} onMouseMove={handleHintMove} onMouseLeave={handleHintUnhover}>
      {props.text}
      <HintBlock html={props.hint} isVisible={hintShowed} />
    </span>
  )
}

function HintBlock(props) {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    const ctx = gsap.context(() => {

      if (props.isVisible) {
        gsap.fromTo(
          element,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.3
          }
        );
      } else {
        gsap.to(
          element,
          {
            opacity: 0,
            duration: 0.3
          }
        );
      }

    }, ref); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert();
  })

  return <span className="hint-block" ref={ref} dangerouslySetInnerHTML={{ __html: props.html }} />;
}

function RotatingText(props) {
  const ref = useRef(null);
  useEffect(() => {
    const ctx = gsap.context(() => {
      circulizeText(ref.current, props.speed, props.size);
    }, ref); // <- IMPORTANT! Scopes selector text
    return () => ctx.revert();
  }, []);

  return (
    <div className="rotating-text" ref={ref}>
      <p>{props.text}</p>
    </div>
  )
}


function handleLinkClick(event) {
  if (isTouchDevice()) {
    //*
    event.preventDefault();

    const body = document.body;
    const appContainer = event.target.closest(".app-container");
    const burger = appContainer.querySelector(".burger");
    const fullscreen = appContainer.querySelector(".fullscreen");

    let hash = event.currentTarget.getAttribute('href');

    if (hash) {
      gsap.to(window, {
        scrollTo: {
          y: appContainer.querySelector(hash).offsetTop
        },
        duration: 0.4,
        onStart: () => {
          body?.classList.remove('no-scroll');
          body?.classList.add('scrolling');
        },
        onComplete: () => {
          if (fullscreen.classList.contains('fullscreen_opened')) {
            burger.click();
          } else {
            body?.classList.remove('scrolling');
          }
          window.location.hash = hash;
          ScrollTrigger.refresh();
        }
      })
    }
    // */
  } else {
    dispatchHashchange();
  }

}

function FullscreenMenu(props) {
  const ref = useRef(null);
  const { appRef } = props;
  const tl = useRef();
  const ctx = useRef();

  const [menuOpened, setMenuOpened] = useState(false);

  function handleMenuClick(event) {
    setMenuOpened(oldValue => !oldValue);
  }

  function handleLinkHover(event) {
    let element = event.target;
    if (element.tagName !== 'A') {
      element = element.closest('a');
    }
    element.classList.add('menu__link_hovered');

    let blurredLinks = ref.current.querySelectorAll(".menu_full .menu__link:not(.menu__link_hovered)");
    let target = element?.dataset.target;
    let itemBgTarget = ref.current.querySelector(`.fullscreen__circle__bg[data-item="${target}"]`);
    let itemBgAll = ref.current.querySelectorAll(`.fullscreen__circle__bg:not([data-item="${target}"])`);

    if (itemBgTarget) {
      gsap.to(
        itemBgTarget,
        {
          duration: 0.3,
          ease: "power1.inOut",
          opacity: 1,
        }
      );
      gsap.to(
        itemBgAll,
        {
          duration: 0.3,
          ease: "power1.inOut",
          opacity: 0,
        }
      );
    }

    for (let i = 0; i < blurredLinks.length; i += 1) {
      blurredLinks[i].classList.add('menu__link_blurred');
    }

  }

  function handleLinkUnhover(event) {
    let element = event.target;
    if (element.tagName !== 'A') {
      element = element.closest('a');
    }
    element.classList.remove('menu__link_hovered');

    let links = ref.current.querySelectorAll(".menu_full .menu__link");
    let itemBgAll = ref.current.querySelectorAll(`.fullscreen__circle__bg`);

    gsap.to(
      itemBgAll,
      {
        duration: 0.3,
        ease: "power1.inOut",
        opacity: 0,
      }
    );

    for (let i = 0; i < links.length; i += 1) {
      links[i].classList.remove('menu__link_blurred');
    }
  }

  //*
  useEffect(() => {

    let ctx = gsap.context(() => {

      const fullscreen = ref.current;
      const body = document.body;

      const showAnim = gsap.from(fullscreen, {
        yPercent: -100,
        paused: true,
        duration: 0.2
      }).progress(1);

      /*ScrollTrigger.create({
        start: "top top",
        end: 99999,
        onUpdate: (self) => {
          if (!body.classList.contains('scrolling') && !body.classList.contains('no-scroll') && !fullscreen.classList.contains('fullscreen_opened')) {
            self.direction === -1 ? showAnim.play() : showAnim.reverse();
          }
        }
      });*/

    }, ref);

    return () => ctx.revert()

  }, [])
  // */

  useEffect(() => {
    ctx.current = gsap.context(() => { }); // nothing initially (we'll add() to the context when endX changes)
    return () => ctx.current.revert();
  }, [ctx]);

  useEffect(() => {
    //console.log('useEffect 2')
    ctx.current.add(() => {

      let links = ref.current.querySelectorAll(".menu_full .menu__link");
      const menuCircle = ref.current.querySelector(".fullscreen__circle");
      const menuBg = ref.current.querySelector(".fullscreen__bg");
      const fullscreen = ref.current;
      const body = document.body;

      tl.current = gsap.timeline({
        onStart: function () {
          fullscreen?.classList.add('fullscreen_animating')
          fullscreen?.classList.remove('fullscreen_animated')
        },
        onComplete: function () {
          fullscreen?.classList.remove('fullscreen_animating')
          fullscreen?.classList.add('fullscreen_animated')
          body?.classList.remove('scrolling');

          if (!fullscreen?.classList.contains('fullscreen_opened')) {
            body?.classList.remove('no-scroll');
          }
        }
      });

      if (menuOpened) {

        tl.current.to(
          menuCircle,
          {
            duration: 0.5,
            ease: "power1.inOut",
            scaleX: 1,
            scaleY: 1,
          }
        );
        tl.current.to(
          menuBg,
          {
            duration: 0.5,
            ease: "power1.inOut",
            opacity: 1,
          },
          "<"
        );

        tl.current.addLabel(`afterMenuBgOpened`);

        for (let i = 0; i < links.length; i += 1) {

          let letters = links[i].querySelectorAll(".animation-item");

          for (let n = 0; n < letters.length; n += 1) {

            let index = getComputedStyle(letters[n]).getPropertyValue('--index');

            tl.current.fromTo(
              letters[n],
              {
                y: "110%"
              },
              {
                duration: 0.3,
                ease: "power1.inOut",
                y: "0%",
              },
              index === '0' ? `afterMenuBgOpened-=0.25` : `>-=0.25`
            );

          }
        }

      } else {

        tl.current.addLabel(`beforeMenuBgClosed`);

        for (let i = 0; i < links.length; i += 1) {

          let letters = links[i].querySelectorAll(".animation-item");

          for (let n = 0; n < letters.length; n += 1) {

            let index = getComputedStyle(letters[n]).getPropertyValue('--index');

            tl.current.to(
              letters[n],
              {
                duration: 0.3,
                ease: "power1.inOut",
                y: "110%",
              },
              index === '0' ? `beforeMenuBgClosed` : `>-=0.25`
            );

          }
        }

        tl.current.to(
          menuCircle,
          {
            duration: 0.5,
            ease: "power1.inOut",
            scaleX: 0,
            scaleY: 0,
          }
        );
        tl.current.to(
          menuBg,
          {
            duration: 0.5,
            ease: "power1.inOut",
            opacity: 0,
          },
          "<"
        );

      }

    })

  }, [menuOpened])

  useEffect(() => {
    //console.log('useEffect Burger')
    let ctx = gsap.context(() => {

      const burger = ref.current.querySelector('.burger');

      gsap.set(burger, {
        pointerEvents: 'none'
      })
      gsap.to(burger, {
        scrollTrigger: {
          trigger: appRef.current.querySelector(".historyscreen"),
          pin: false,
          start: 'top top',
          end: '+=30%',
          scrub: 0.3,
        },
        onUpdate: () => {
          if (burger.style.opacity < 0.5) {
            burger.style['pointer-events'] = 'none';
          } else {
            burger.style['pointer-events'] = '';
          }
        },
        opacity: 1,
      })

    }, ref)

    return () => ctx.revert()

  })

  return (
    <div className={"fullscreen " + (menuOpened ? "fullscreen_opened" : "")} ref={ref}>
      <button className="burger" aria-label="Меню" onClick={handleMenuClick}>
        <Constants.FunicLogo className="burger__logo" />
      </button>
      <div className="fullscreen__menu">
        <ul className="menu menu_full">
          <li className="menu__item">
            <a className="menu__link" href="#history" data-target="history" onClick={handleLinkClick} onMouseEnter={handleLinkHover} onTouchStart={handleLinkHover} onMouseLeave={handleLinkUnhover} onTouchEnd={handleLinkUnhover}>Історія</a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="#gallery" data-target="gallery" onClick={handleLinkClick} onMouseEnter={handleLinkHover} onTouchStart={handleLinkHover} onMouseLeave={handleLinkUnhover} onTouchEnd={handleLinkUnhover}>Галерея</a>
          </li>
          <li className="menu__item">
            <a className="menu__link" href="#contact" data-target="contact" onClick={handleLinkClick} onMouseEnter={handleLinkHover} onTouchStart={handleLinkHover} onMouseLeave={handleLinkUnhover} onTouchEnd={handleLinkUnhover}>Контакти</a>
          </li>
        </ul>
      </div>
      <div className="fullscreen__circle">
        <img data-src={Constants.history_bg} className="lazyload fullscreen__circle__bg" alt="history" data-item="history" loading="lazy" />
        <img data-src={Constants.gallery_bg} className="lazyload fullscreen__circle__bg" alt="gallery" data-item="gallery" loading="lazy" />
        <img data-src={Constants.contact_bg} className="lazyload fullscreen__circle__bg" alt="contact" data-item="contact" loading="lazy" />
      </div>
      <div className="fullscreen__bg" onClick={handleMenuClick}></div>
    </div>
  )
}

function TopMenu(props) {
  const ref = useRef(null);
  const tl = useRef();
  const { appRef } = props;

  useEffect(() => {

    let ctx = gsap.context(() => {

      const body = document.body;
      const menuTop = ref.current;
      let menuItems = menuTop.querySelectorAll('.menu__item')

      if (!body.classList.contains('scrolling')) {
        gsap.fromTo(menuTop,
          {
            y: "0%"
          },
          {
            y: "-100%",
            scrollTrigger: {
              trigger: appRef.current.querySelector(".homescreen"),
              pin: false,
              start: 'center top',
              end: '+=100px',
              scrub: 0.3,
            },
          })
      }

      tl.current = gsap.timeline({
        scrollTrigger: {

          trigger: menuTop,
          start: "top center",
          scrub: false,
          once: true,
          //markers: true
        }
      })

      for (let i = 0; i < menuItems.length; i += 1) {
        let item = menuItems[i];
        tl.current.fromTo(item,
          {
            y: "-50%",
            opacity: 0,
          },
          {
            y: "0%",
            opacity: 1,
            duration: 0.5,
            delay: i === 0 ? 0.5 : 0
          },
          i === 0 ? "" : `-=0.4`
        )
      }

    }, ref);

    return () => ctx.revert()

  }, [])

  function handleLinkHover(event) {
    let link = event.currentTarget;
    let firstSpan = link.querySelector('span:first-child');
    let lastSpan = link.querySelector('span:last-child');

    gsap.fromTo(
      firstSpan,
      {
        x: "0%",
        y: "0%",
      },
      {
        duration: 0.3,
        ease: "power1.inOut",
        x: "-100%",
        y: "-100%",
      }
    );
    gsap.fromTo(
      lastSpan,
      {
        x: "100%",
        y: "100%",
      },
      {
        duration: 0.3,
        ease: "power1.inOut",
        x: "0%",
        y: "0%",
        onComplete: function () {
          firstSpan.style.transform = "translate(0%, 0%)";
          lastSpan.style.transform = "translate(100%, 100%)";
        }
      }
    );

  }

  return (
    <ul className="menu menu_top" ref={ref}>
      <li className="menu__item">
        <a className="menu__link" href="#history" onClick={handleLinkClick} onMouseEnter={handleLinkHover}><span>Історія</span><span>Історія</span></a>
      </li>
      <li className="menu__item">
        <a className="menu__link" href="#gallery" onClick={handleLinkClick} onMouseEnter={handleLinkHover}><span>Галерея</span><span>Галерея</span></a>
      </li>
      <li className="menu__item">
        <a className="menu__link" href="#contact" onClick={handleLinkClick} onMouseEnter={handleLinkHover}><span>Контакти</span><span>Контакти</span></a>
      </li>
    </ul>
  )
}

function HomeScreen(props) {
  const ref = useRef(null);
  const tl = useRef();
  const { appRef } = props;

  const [isLoading, setIsLoading] = useState(true);

  const VIDEO_WIDTH_PX = 1280.0;
  const VIDEO_HEIGHT_PX = 720.0;

  const handleVideoLoaded = (event) => {
    //console.log('Data is loaded!')

    const element = event.target;
    setIsLoading(false);

    /**
     * Fix bug for iOS when object-fit: cover for video makes it jump on start
     * @see https://developer.apple.com/forums/thread/707247
     */
    new ResizeObserver(function () {

      let scale, height, width;
      let transX, transY;
      if (element.clientWidth / element.clientHeight > VIDEO_WIDTH_PX / VIDEO_HEIGHT_PX) {
        // Screen is wide, so video capped on height.
        height = element.clientHeight;
        width = (VIDEO_WIDTH_PX / VIDEO_HEIGHT_PX) * height;
        scale = element.clientWidth / width;

        transY = (-(scale - 1) * height * 0.5) / scale;
        transX = 0.0;
      } else {
        width = element.clientWidth;
        height = (VIDEO_HEIGHT_PX / VIDEO_WIDTH_PX) * width;
        scale = element.clientHeight / height;

        transY = 0.0;
        transX = (-(scale - 1) * width * 0.5) / scale;
      }

      element.style.transformOrigin = "top left";
      element.style.transform = "scale(" + scale + ") translate(" + transX + "px, " + transY + "px)";

      gsap.to(element,
        {
          opacity: 1,
          duration: 0.4,
          delay: 0.3
        }
      )

    }).observe(element);
  }

  useEffect(() => {
    console.log("HomeScreen")

    const ctx = gsap.context(() => {

      const element = ref.current;
      const appContainer = appRef.current;
      const burger = appContainer.querySelector(".burger");
      const funicHeading = element.querySelector(".funic-heading");
      const funicTitle = element.querySelector(".funic-title");
      const funicSlogan = element.querySelector(".funic-slogan");
      const rotatingText = element.querySelector(".rotating-text");
      const bgCopyright = element.querySelector(".homescreen__bg__copyright");
      const funicLogoLeft = element.querySelector(".funic-logo path:first-child");
      const funicLogoRight = element.querySelector(".funic-logo path:last-child");

      gsap.to([burger], {
        //immediateRender:false,
        scrollTrigger: {
          trigger: appContainer.querySelector(".historyscreen"),
          start: 'top top',
        },
        opacity: 1,
      })

      gsap.to([funicHeading, burger], {
        scrollTrigger: {
          trigger: element,
          pin: false,
          start: 'top top',
          scrub: true,
        },
        opacity: 0,
        y: '-50%',
      })

      tl.current = gsap.timeline({
        scrollTrigger: {
          trigger: element,
          start: "top center",
          scrub: false,
          once: true,
          //markers: true
        },
        duration: 1
      })
        .fromTo(funicTitle,
          {
            y: "25%",
            opacity: 0
          },
          {
            y: "0%",
            opacity: 1,
            delay: 0.5
          }
        )
        .fromTo(funicSlogan,
          {
            y: "-25%",
            opacity: 0
          },
          {
            y: "0%",
            opacity: 1,
            delay: 0.5
          },
          "<-=0.5"
        )
        .fromTo(funicLogoLeft,
          {
            x: "-25%",
            opacity: 0
          },
          {
            x: "0%",
            opacity: 1,
            delay: 0.5
          },
          "<-=0.5"
        )
        .fromTo(funicLogoRight,
          {
            x: "25%",
            opacity: 0
          },
          {
            x: "0%",
            opacity: 1,
            delay: 0.5
          },
          "<-=0.5"
        )
        .fromTo([rotatingText, bgCopyright],
          {
            opacity: 0
          },
          {
            opacity: 1,
            delay: 0.5
          },
          "<-=0.5"
        )

    }, ref)

    return () => ctx.revert();

  }, [])

  return (
    <section className="homescreen" ref={ref} data-bgcolor="#2482d9">
      <div className="homescreen__bg">
        {/*<iframe width="100%" height="100%" src="https://www.youtube.com/embed/hQbGPrgKfh0?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&modestbranding=1" title="Київський Фунікулер" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>*/}
        {isLoading && <div className="homescreen__bg__preloader">Відео <br />завантажується</div>}
        <video muted loop playsInline autoPlay src={Constants.intro_video} onLoadedData={handleVideoLoaded} type="video/mp4" width="100%" height="100%" />
        <div className="funic-heading">
          <Constants.FunicLogo className="funic-logo" />
          <h1 className="funic-title">Київський<br />фунікулер</h1>
          <p className="funic-slogan">працює з 1905 року</p>
        </div>
        <p className='homescreen__bg__copyright'>Відео з каналу <a href="https://www.youtube.com/@kostiantynpavliuk5474" target="_blank" rel="noopener noreferrer">Kostiantyn Pavliuk</a></p>
        <RotatingText text="гортай вниз гортай вниз" speed="2" size="3" />
      </div>
    </section>
  )
}

function History(props) {
  const ref = useRef(null);
  let history_tl_0 = useRef();
  let history_tl_1 = useRef();
  let history_tl_2 = useRef();
  let history_tl_4 = useRef();
  let history_tl_4_2 = useRef();
  let history_tl_5 = useRef();
  let history_tl_5_2 = useRef();
  let image_tl = useRef();
  let gallery_tl = useRef();
  const { appRef } = props;

  useEffect(() => {
    console.log('History');

    const ctx = gsap.context(() => {

      const element = ref.current;
      const appContainer = appRef.current;

      ScrollTrigger.defaults({
        preventOverlaps: true,
        fastScrollEnd: false
      });

      let years = element.querySelectorAll(".years:not(#historyIntroText) > *");

      for (let i = 0; i < years.length; i += 1) {
        explodeText(years[i]);
      }

      history_tl_0.current = gsap.timeline({
        scrollTrigger: {
          trigger: element.querySelector(".history-block_0 .title-block"),
          start: "top 70%",
          scrub: false,
          once: true,
          //markers: true
        }
      })
        .fromTo(element.querySelector("#water-fall"), //https://codepen.io/joshkirk/pen/aNRxMK
          {
            attr: {
              y: "15vw",
              x: "-50%",
            }
          },
          {
            attr: {
              y: "-5vw",
              x: 0,
            },
            duration: 2,
            ease: "power1.inOut",
          }
        )

      history_tl_1.current = gsap.timeline({
        defaults: { overwrite: "auto" },
        scrollTrigger: {
          scroller: appContainer.querySelector(".smooth-scroll"),
          trigger: element.querySelector(".history-block_1 .title-block"),
          start: "top top",
          end: "+=500%", //"bottom top",
          scrub: 1, //true,
          pin: element.querySelector(".history-block_1 .title-block"),
          //markers: true,
        },
      })

      history_tl_1.current.to(element.querySelector(".stairs-draw__line"), {
        opacity: 1,
        duration: 10,
      })
        .to(element.querySelector(".stairs-draw__line2"), {
          opacity: 1,
          duration: 10
        })
        .to(element.querySelectorAll(".stairs-draw line:not(.stairs-draw__line):not(.stairs-draw__line2)"), {
          opacity: 1,
          duration: 10
        })
        .to(element.querySelectorAll(".stairs-draw rect"), {
          opacity: 1,
          duration: 10
        })
        .fromTo(element.querySelectorAll(".stairs-draw > path, .stairs-draw > g"),
          {
            x: "-2005px",
            y: "730px",
          },
          {
            //opacity: 1,
            x: 0,
            y: 0,
            duration: 60
          })
        .fromTo(element.querySelectorAll(".stairs-draw > g path[fill='#92DFF4']"), //move window blinks along with train
          {
            x: "-50%",
          },
          {
            x: "20%",
            duration: 60
          },
          "<"
        )
        .to(element.querySelector(".history-block_1 .heading"), {
          opacity: 1,
          duration: 10
        })
        .to(element.querySelector(".stairs-draw"), {
          opacity: 0,
          duration: 20
        }, "<")
        .to(element.querySelector(".history-block_1 .heading"), {
          rotate: 0,
          x: 0,
          duration: 20
        })
        .to(element.querySelectorAll(".history-block_1 .years .years__top, .history-block_1 .years .years__bottom"), {
          x: "0%",
          duration: 60
        })

      history_tl_2.current = gsap.timeline({
        scrollTrigger: {
          trigger: element.querySelector(".history-block_2 .title-block"),
          start: "top 90%",
          scrub: false,
          once: true,
        }
      })
        .addLabel(`beforeHeadingShowed`)
        .to(element.querySelector(".history-block_2 .heading"), {
          opacity: 1,
          duration: 0.5,
        })
        .addLabel(`afterHeadingShowed`);

      let yearsFromHistory2 = element.querySelectorAll(".history-block_2 .years > *");

      for (let i = 0; i < yearsFromHistory2.length; i += 1) {
        let letters = yearsFromHistory2[i].querySelectorAll(".animation-item");

        for (let n = 0; n < letters.length; n += 1) {

          let index = getComputedStyle(letters[n]).getPropertyValue('--index');

          history_tl_2.current.fromTo(
            letters[n],
            {
              x: "-20%",
              opacity: 0,
            },
            {
              duration: 0.35,
              ease: "power1.inOut",
              x: "0%",
              opacity: 1,
            },
            index === '0' ? `beforeHeadingShowed` : `>-=0.3`
          );

        }
      }

      history_tl_4.current = gsap.timeline({
        scrollTrigger: {

          trigger: element.querySelector(".history-block_4 .title-block"),
          start: "top 90%",
          scrub: false,
          once: true,
          //markers: true
        }
      })
        .addLabel(`beforeHeadingShowed`)
        .to(element.querySelector(".history-block_4 .heading"), {
          opacity: 1,
          duration: 0.5
        })
        .addLabel(`afterHeadingShowed`);

      let yearsFromHistory4 = element.querySelectorAll(".history-block_4 .years > *");

      for (let i = 0; i < yearsFromHistory4.length; i += 1) {

        let firstWord = yearsFromHistory4[i].querySelector(".word:first-child");
        let lastWord = yearsFromHistory4[yearsFromHistory4.length - (i + 1)].querySelector(".word:last-child");

        history_tl_4.current.fromTo(
          firstWord,
          {
            opacity: 0,
          },
          {
            duration: 0.1,
            ease: "none",
            opacity: 1,
          },
          //i === 0 ? `afterHeadingShowed` : `afterFirstWord-${i - 1}`
          i === 0 ? `afterHeadingShowed` : `<+=0.1`
        )
          .addLabel(`afterFirstWord-${i}`)
          .fromTo(
            lastWord,
            {
              opacity: 0,
            },
            {
              duration: 0.1,
              ease: "none",
              opacity: 1,
            },
            //i === 0 ? `afterHeadingShowed` : `<-=afterLastWord-${i - 1}`
            i === 0 ? `afterHeadingShowed` : `<+=0.1`
          )
          .addLabel(`afterLastWord-${i}`);

        if (i === yearsFromHistory4.length - 1) {
          history_tl_4.current.fromTo(
            [yearsFromHistory4[0].querySelector(".word:first-child"), yearsFromHistory4[i].querySelector(".word:last-child")],
            {
              color: 'rgba(255,255,255,0)',
            },
            {
              duration: 0.4,
              ease: "power1.inOut",
              color: 'rgba(255,255,255,.8)',
            },
          );
        }

      }

      history_tl_4_2.current = gsap.timeline({
        scrollTrigger: {

          trigger: element.querySelector(".history-block_4 .image_funic-1956"),
          start: "top 90%",
          end: "bottom 60%",
          scrub: true,
          once: true,
          //markers: true
        },
        onComplete: () => element.querySelector(".history-block_4 .image_funic-1956 img").classList.add('animation-complete')
      })
        .fromTo(element.querySelector(".history-block_4 .image-with-text_funic-1956:not(.was-wrapped) .image_funic-1956 img"),
          {
            x: "100%",
            y: "-60%",
          },
          {
            x: "-30%",
            y: "10%",
            duration: 0.7,
          }
        )
        .fromTo(element.querySelector(".history-block_4 .image-with-text_funic-1956.was-wrapped .image_funic-1956 img"),
          {
            x: "100%",
            y: "-60%",
          },
          {
            x: "0%",
            y: "0%",
            duration: 0.7,
          }
        )

      history_tl_5.current = gsap.timeline({
        scrollTrigger: {
          trigger: element.querySelector(".history-block_5 .title-block"),
          start: "top 90%",
          scrub: false,
          once: true,
          //markers: true
        }
      })
        .addLabel(`beforeHeadingShowed`)
        .to(element.querySelector(".history-block_5 .heading"), {
          opacity: 1,
          duration: 0.5
        })
        .addLabel(`afterHeadingShowed`);

      history_tl_5_2.current = gsap.timeline({
        scrollTrigger: {

          trigger: element.querySelector(".history-block_5 .title-block"),
          start: "top bottom",
          end: "bottom top",
          scrub: false,
          once: true,
          //markers: true
        }
      })

      let yearsFromHistory5 = element.querySelectorAll(".history-block_5 .years > *");

      for (let i = 0; i < yearsFromHistory5.length; i += 1) {

        history_tl_5_2.current.fromTo(
          yearsFromHistory5[i],
          {
            x: i === 0 ? "0%" : "-50%",
          },
          {
            duration: 7,
            ease: "none",
            x: i === 0 ? "-50%" : "0%",
            repeat: "-1",
            onRepeat: () => yearsFromHistory5[i].classList.toggle('switch-colors')
          },
          "<"
        );

      }

      const textsToAnimate = element.querySelectorAll(".text_heading, .text_semiwide, .heading.text_align-center, .heading.text_align-left");
      textsToAnimate.forEach((text, i) => {

        ScrollTrigger.create({
          //markers: true,
          trigger: text,
          start: "top 80%",
          onEnter: (self) => {
            gsap.to(text, {
              y: "0%",
              opacity: 1
            })
          },
          onLeaveBack: (self) => {
            gsap.to(text, {
              y: "0%",
              opacity: 1
            })
          },
        });

      });

      const imagesToAnimate = element.querySelectorAll(".animate-image-block");
      imagesToAnimate.forEach((image, i) => {

        let imgBoxShadow = getComputedStyle(image.querySelector('img')).boxShadow;
        let imgBorderRadius = getComputedStyle(image.querySelector('img')).borderRadius;

        image_tl.current = gsap.timeline({
          scrollTrigger: {

            //markers: true,
            trigger: image,
            scrub: true,
            start: "top 130%",
            end: "bottom 70%",
          }
        })
          .set(image, {
            borderRadius: imgBorderRadius,
          })
          .set(image.querySelector('img'), {
            boxShadow: 'none',
          })
          .fromTo(image.querySelector('.animate-image-block__content img'),
            {
              y: "-50%",
            },
            {
              y: "0%",
            }
          )
          .to(image, {
            boxShadow: imgBoxShadow,
            duration: 0.3
          })

      });

      const galleriesToAnimate = element.querySelectorAll(".gallery");
      galleriesToAnimate.forEach((gallery, i) => {

        const defaultPaddingTop = gsap.utils.clamp(24, 110, window.innerHeight * 0.2); //'clamp(24px, 20vw, 110px)';

        let imagesToAnimate = gallery.querySelectorAll(".image");

        imagesToAnimate.forEach((image, i) => {

          gallery_tl.current = gsap.timeline({
            scrollTrigger: {

              //markers: true,
              trigger: image.parentNode,
              scrub: true,
              start: "top bottom",
              end: "bottom bottom",
            }
          })
            .fromTo(image,
              {
                y: "0px"
              },
              {
                y: (i + 1) % 2 === 0 ? `-${defaultPaddingTop}` : `${defaultPaddingTop}`,
              }
            )

        });

      });

    }, ref); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert();

  }, []);
  //}, [window.innerWidth]);

  return (
    <section className="historyscreen" ref={ref}>
      <HistoryIntro />
      <History1 />
      <History2 />
      <History3 />
      <History4 />
      <History5 />
      <History6 />
    </section>
  )
}

function HistoryIntro() {

  return (
    <section className="history-block history-block_0" data-bgcolor="#0F0C29" id="history">
      <div className="title-block">
        <svg width="800" height="120" preserveAspectRatio="none">
          <defs>
            <pattern id="water" width=".25" height="1.1" patternContentUnits="objectBoundingBox">
              <path fill="white" d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,1z" />
            </pattern>
            <text id="historyIntroText" className="years">Історія</text>
            <mask id="maskHistoryIntroText">
              <use x="50%" y="100%" textAnchor="middle" href="#historyIntroText" fill="white" />
            </mask>
          </defs>
          <use href="#historyIntroText" x="50%" y="100%" textAnchor="middle" fill="transparent" />
          <rect id="water-fall" mask="url(#maskHistoryIntroText)" fill="url(#water)" x="0" y="0" width="1600" height="120" />
        </svg>
        {/*<h2 className="years">Історія</h2>*/}
      </div>
    </section>
  )

}

function History1() {
  /*
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const getData = async () => {
    //const { data } = await axios.get(`https://sunset.bogdan.kyiv.ua/funicular/wp-json/wp/v2/pages/10/`);
    const { data } = await axios.get(`http://localhost:1337/api/histories/1`);
    setData(data.data);
    setLoading(false);
    console.log(data)
  };

  useEffect(() => {
    getData();
  }, [])
  // */

  return (
    <section className="history-block history-block_1" data-bgcolor="#0F0C29">
      <div id="history-1-trigger" className='trigger'></div>
      <div className="title-block">
        <Constants.Stairs className="stairs-draw" />
        <h2 className="heading">{/*!isLoading && data.attributes.Title*/}Михайлівський підйом</h2>
        <div className="years">
          <span className="years__top">1902</span>
          <span className="years__bottom">1905</span>
        </div>
      </div>

      <div className="text text_wide text_heading color-white mt-3 mb-1">
        <p>Ідею побудувати фунікулер запропонував <Hint text="Артур Абрагамсон" hint={`<img   src=${Constants.aa} alt="AA" />`} />, або «інженер в квадраті», як його називав міністр шляхів сполучення Сергій Вітте.
          Проєкт розробили інженер <Hint text="Микола П’ятницький" hint={`<img   src=${Constants.mp} alt="MP" />`} /> і архітектор <Hint text="Олександр Баришников" hint={`<img   src=${Constants.ob} alt="OB" />`} />.
        </p>
      </div>
      <div className="image-with-text image-with-text_align-center mb-2">
        <figure className="image image_align-left">
          <img className="lazyload" data-src={Constants.history_1_1} alt="history_1_1" width="960" height="912" />
        </figure>
        <div className="text color-white">
          <p>Зрештою незвичайна транспортна система отримала офіційну назву «Михайлівський електричний канатний підйом».</p>
        </div>
      </div>
      <figure className="image image_large image_align-center mt-2">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_1_2} alt="history_1_2" width="1440" height="1040" /></AnimateBlock>
      </figure>
      <div className="text text_wide text_heading text_align-left color-white mt-2 mb-2">
        <p>
          Ось кілька важливих цифр:
        </p>
        <ul>
          <li>створювали фунікулер протягом 1902-1905 років;</li>
          <li>урочисте відкриття відбулося 7-го (20-го за старим стилем) травня 1905 року;</li>
          <li>увесь проєкт коштував концесіонеру, тобто Товариству Київської міської залізниці, 230 тисяч рублів;</li>
          <li>і вже за рік підйомом скористалися 500 тисяч пасажирів.</li>
        </ul>
      </div>
      <figure className="image">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_1_3} alt="history_1_3" width="1720" height="1122" /></AnimateBlock>
      </figure>
      <div className="text text_semiwide text_heading color-white mb-2 mt-2">
        <p>
          Впроваджені на Михайлівському підйомі інженерні рішення на той час були найбільш сучасними та прогресивними. Так, обладнання фунікулера та візки вагонів виготовили у Швейцарії, яка мала великий досвід у створенні канатних доріг.
        </p>
      </div>
      <div className="gallery gallery_cols_2 color-white mb-2">
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_1_gallery_1} alt="history_1_gallery_1" width="800" height="506" />
          <figcaption>Вид на Поділ з верхньої станції. 1905 рік</figcaption>
        </figure>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_1_gallery_2} alt="history_1_gallery_2" width="800" height="528" />
          <figcaption>Так спочатку виглядала Нижня станція. 1906 рік</figcaption>
        </figure>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_1_gallery_3} alt="history_1_gallery_3" width="800" height="527" />
          <figcaption>Вагон зразка 1907 року та вид на торговий та промисловий Поділ</figcaption>
        </figure>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_1_gallery_4} alt="history_1_gallery_4" width="800" height="528" />
          <figcaption>Маловідома дореволюційна листівка з видом зимового фунікулера. 1910 рік</figcaption>
        </figure>
      </div>
      <div id="history-1-end-trigger" className='trigger'></div>
    </section>
  )
}

function History2() {

  return (
    <section className="history-block history-block_2" data-bgcolor="#16222A">
      <div id="history-2-trigger" className='trigger'></div>
      <div className="title-block">
        <h2 className="heading">Підйом імені Іванова</h2>
        <div className="years">
          <span className="years__middle">1928-1929</span>
        </div>
      </div>
      <div className="text text_semiwide color-white mb-1">
        <p>Влітку 1928 року під час поточного ремонту сталася аварія: у процесі заміни каната верхній вагон зірвався і зіткнувся з нижнім. Обійшлося без жертв, а ось вагони залишилися зруйновані повністю.</p>
      </div>
      <div className="image-with-text image-with-text_align-top">
        <div className="color-white mt-2">
          <p>
            Уже скоро київські спеціалісти відновили рух. Нові вагони збудували на заводі імені Домбаля (нині це Київський завод електротранспорту – КЗЕТ). Складали їх прямо на місці.
          </p>
        </div>
        <figure className="image image_align-right">
          <img className="lazyload" data-src={Constants.history_2_1} alt="history_2_1" width="960" height="912" />
        </figure>
      </div>
      <figure className="image color-white mt-1 mb-1">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_2_2} alt="history_2_2" width="1720" height="1189" /></AnimateBlock>
        <figcaption>Група конструкторів та робітників заводу ім. Домбаля на тлі нового вагона</figcaption>
      </figure>
      <div className="image-with-text image-with-text_align-bottom mb-2">
        <figure className="image color-white">
          <img className="lazyload" data-src={Constants.history_2_3} alt="history_2_3" width="800" height="1187" />
          <figcaption>Випробування нових вагонів після ремонту. 1929 рік</figcaption>
        </figure>
        <div className="color-white mb-2">
          <p>
            Оновленим підйомом перші пасажири скористалися 2 травня 1929 року. <br />
            А через рік транспорт дістав і нову назву — «Підйом ім. Іванова» (на честь радянського державного діяча).
          </p>
        </div>
      </div>
      <figure className="image image_medium image_align-center color-white mb-2">
        <img className="lazyload" data-src={Constants.history_2_4} alt="history_2_4" width="800" height="924" />
        <figcaption>Фото 1930 року</figcaption>
      </figure>
      <div id="history-2-end-trigger" className='trigger'></div>
    </section>
  )
}

function History3() {

  return (
    <section className="history-block history-block_3 color-white" data-bgcolor="#414345">
      <div id="history-3-trigger" className='trigger'></div>
      <h2 className="heading text_align-left mt-3 mb-3">Під час Другої світової війни фунікулер працював із перебоями.</h2>
      <div className="image-with-text image-with-text_align-top">
        <div className="mt-2">
          <p>
            Після звільнення міста підйомник пройшов невеликий відновлювальний ремонт. 27 листопада 1943 року його знову запустили.
          </p>
        </div>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_3_1} alt="history_3_1" width="800" height="1156" />
          <figcaption>Верхня станція та естакада у 1944 році</figcaption>
        </figure>
      </div>
      <figure className="image mt-2 mb-3">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_3_2} alt="history_3_2" width="1720" height="1258" /></AnimateBlock>
        <figcaption>Ось так виглядала верхня станція у 1943 році</figcaption>
      </figure>
      <div id="history-3-end-trigger" className='trigger'></div>
    </section>
  )
}

function History4() {

  return (
    <section className="history-block history-block_4 color-white" data-bgcolor="#6d193b">
      <div id="history-4-trigger" className='trigger'></div>
      <div className="title-block">
        <h2 className="heading">Друга реконструкція</h2>
        <div className="years">
          <span className="years__top">1956 1958</span>
          <span className="years__middle">1956 1958</span>
          <span className="years__bottom">1956 1958</span>
        </div>
      </div>
      <div className="text text_semiwide mt-2 mb-1 mr-0">
        <p>У 1956 році для підйомника сконструювали суцільнометалеві вагони К-56 з каскадним кузовом напівобтічної форми, автоматичними дверима та м'якими диванами в салоні.</p>
      </div>
      <div className="image-with-text image-with-text_funic-1956 image-with-text_align-top mb-2">
        <div className="text text_heading">
          <p>
            А ескіз особисто затверджував головний архітектор Києва.
          </p>
        </div>
        <figure className="image image_funic-1956">
          <img className="lazyload" data-src={Constants.history_4_1} alt="history_4_1" width="900" height="700" />
        </figure>
      </div>
      <div className="image-with-text image-with-text_align-center mb-2">
        <div>
          <p>
            Для заміни вагонів біля нижньої станції збудували тимчасову криву, по якій старі вагони спустили на вулицю Боричів Тік.
          </p>
        </div>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_4_2} alt="history_4_2" width="800" height="509" />
        </figure>
      </div>
      <div className="text text_heading text_align-center mb-2">
        <p>Після цього їх замінили на нові.</p>
      </div>
      <figure className="image mb-2">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_4_3} alt="history_4_3" width="1720" height="1081" /></AnimateBlock>
      </figure>
      <div className="gallery gallery_cols_2 color-white">
        <figure className="image mb-4">
          <img className="lazyload" data-src={Constants.history_4_4} alt="history_4_4" width="1024" height="657" />
          <figcaption>Нові вагони піднімають на лінію</figcaption>
        </figure>
        <figure className="image mb-2">
          <img className="lazyload" data-src={Constants.history_4_5} alt="history_4_5" width="1024" height="689" />
          <figcaption>Фасад верхньої станції у 1956 році</figcaption>
        </figure>
      </div>
      <h2 className="heading text_align-center mb-2">У 1963-1964 pоках пройшов другий етап реконструкції</h2>
      <div className="text text_semiwide mb-2">
        <p>У машинному залі встановили нове сучасне обладнання, а фасад верхньої станції повністю перебудували на кшталт тогочасної архітектури: прості й лаконічні форми, керамічна плитка, неонові літери.</p>
      </div>
      <figure className="image mb-3">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_4_6} alt="history_4_6" width="1720" height="1081" /></AnimateBlock>
      </figure>
      <div id="history-4-end-trigger" className='trigger'></div>
    </section>
  )
}

function History5() {

  return (
    <section className="history-block history-block_5 color-white" data-bgcolor="#1488CC">
      <div id="history-5-trigger" className='trigger'></div>
      <div className="title-block">
        <h2 className="heading">Нова реконструкція</h2>
        <div className="years">
          <span className="years__top">1983 1983 1983 1983 1983 1983</span>
          <span className="years__bottom">1984 1984 1984 1984 1984 1984</span>
        </div>
      </div>
      <div className="text text_wide mt-3 mb-1">
        <p>Під час цього ремонту фунікулер розібрали майже вщент.<br />
          «Київпроєкту» доручили розробити ескізи нових павільйонів станцій. У планах було встановити тягове обладнання, а нові й комфортніші вагони з покращеною гальмівною системою спроєктував та побудував КЗЕТ.</p>
      </div>
      <div className="image-with-text image-with-text_align-center mb-3">
        <figure className="image image_align-left">
          <img className="lazyload" data-src={Constants.history_5_1} alt="history_5_1" width="960" height="592" />
        </figure>
        <div className="text">
          <p>
            Демонтаж вагонів К-56.<br />1983 рік
          </p>
        </div>
      </div>
      <div className="image-with-text image-with-text_align-top mb-2">
        <div className="text mt-3">
          <p>
            Встановлення на рейки нового вагона К-80.<br />1984 рік
          </p>
        </div>
        <figure className="image image_align-right">
          <img className="lazyload" data-src={Constants.history_5_2} alt="history_5_2" width="960" height="1254" />
        </figure>
      </div>
      <h2 className="heading text_align-center mb-2">Оновлений фунікулер відкрився <br />29 грудня 1984 року.</h2>
      <figure className="image mb-2">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_5_3} alt="history_5_3" width="1713" height="1163" /></AnimateBlock>
      </figure>
      <div className="text text_heading text_semiwide mb-2">
        <p>
          Нижня станція була відбудована з нуля, а навколишня територія повністю приведена до ладу.
        </p>
      </div>
      <figure className="image mb-1">
        <AnimateBlock><img className="lazyload" data-src={Constants.history_5_4} alt="history_5_4" width="1713" height="1163" /></AnimateBlock>
      </figure>
      <div id="history-5-end-trigger" className='trigger'></div>
    </section>
  )
}

function History6() {

  return (
    <section className="history-block history-block_6 color-white" data-bgcolor="#29356f">
      <div id="history-6-trigger" className='trigger'></div>
      <h2 className="heading text_align-left mb-4">І після реконструкції 1984-го фунікулер уже не змінював свого вигляду. </h2>
      <h2 className="heading text_align-left mb-2">Хіба що кілька разів кольори вагончиків.</h2>
      <div className="gallery gallery_cols_2 color-white mb-1">
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_6_gallery_1} alt="history_6_gallery_1" width="800" height="600" />
          <figcaption>1995 рік</figcaption>
        </figure>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_6_gallery_2} alt="history_6_gallery_2" width="800" height="600" />
          <figcaption>2008 рік</figcaption>
        </figure>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_6_gallery_3} alt="history_6_gallery_3" width="800" height="600" />
          <figcaption>2019 рік</figcaption>
        </figure>
        <figure className="image">
          <img className="lazyload" data-src={Constants.history_6_gallery_4} alt="history_6_gallery_4" width="800" height="600" />
          <figcaption>Під час проведення Євро-2012</figcaption>
        </figure>
      </div>
      <div id="history-6-end-trigger" className='trigger'></div>
    </section>
  )
}

function Contact() {

  return (
    <section className="contact-block mb-1" id="contact" data-bgcolor="#2482d9">
      <div className='contact-block__inner'>
        <div className="text">
          <p>
            <strong>Розташування:</strong> Київ, Володимирська Гірка<br />
            <strong>Телефон:</strong> <a href="tel:+380442546560">044 254 6560</a><br />
            <strong>Години роботи:</strong> кожного дня, з 7 ранку до 10 вечора (години роботи можуть змінюватись через військовий стан)<br />
            <strong>Вартість проїзду:</strong> 8 грн.
          </p>
        </div>
        <div className="contact-block__map">
          <iframe title='Мапа' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2540.0846799051806!2d30.5216802!3d50.4581478!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4ce4105c8ab8b%3A0x9321ce28c11b4c8f!2z0JrQuNGX0LLRgdGM0LrQuNC5INGE0YPQvdGW0LrRg9C70LXRgA!5e0!3m2!1suk!2sua!4v1670418503315!5m2!1suk!2sua" width="600" height="450" allowFullScreen="" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </section>
  )

}

function Sources() {

  return (
    <section className="sources-block mb-4 color-white">
      <div className="text text_align-center">
        <p>
          <strong>Джерела:</strong><br />
          <a href="https://project.weekend.today/funicular" target="_blank" rel="noreferrer">https://project.weekend.today/funicular</a>,<br />
          <a href="https://kyivpastfuture.com.ua/kyyivskyj-funikuler-istoriya/" target="_blank" rel="noreferrer">https://kyivpastfuture.com.ua/kyyivskyj-funikuler-istoriya/</a>.
        </p>
      </div>
    </section>
  )

}

function Footer() {

  return (
    <section className="footer-block color-white" data-bgcolor="#2482d9">
      <div className="text text_align-center">
        Дизайнер та розробник <a href="mailto:iam@bogdan.kyiv.ua" target="_blank" rel="noreferrer">Богдан Бендзюков</a>. <br /> © {new Date().getFullYear()} Усі права захищені.
      </div>
    </section>
  )

}

export { RotatingText, FullscreenMenu, TopMenu, HomeScreen, History, Hint, HintBlock, Contact, Footer, Sources, AppContainer };