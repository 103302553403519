import React, { useState, useEffect, useRef } from 'react';
import { Fancybox } from "@fancyapps/ui";
import { gsap } from "gsap";
import Scrollbar from 'smooth-scrollbar';
import Cursor from './cursor';
import { explodeText, importAll } from './core.js';

const galleryImages = importAll(require.context('../images/gallery/thumbs', false, /\.(png|jpe?g|svg)$/));

function GalleryItem({ data, handleGalleryHover, handleGalleryUnhover }) {

    return (
        <>
            <figure className="image" onMouseEnter={handleGalleryHover} onTouchStart={handleGalleryHover} onMouseLeave={handleGalleryUnhover} onTouchEnd={handleGalleryUnhover}>
                <a href={`gallery/${data.filename}`}><img src={galleryImages[data.filename]} alt={data.title} width={data.thumbWidth} height={data.thumbHeight} /></a>
                <figcaption>
                    {data.title}<br />
                    {data.ownerType === "source" && `Джерело: ${data.ownerURL}`}
                    {data.ownerType === "author" && `Автор: ${data.ownerTitle}`}
                    {data.ownerType === "both" && `Джерело: ${data.ownerURL}`}
                    {data.ownerType === "both" && <br />}
                    {data.ownerType === "both" && `Автор: ${data.ownerTitle}`}
                </figcaption>
            </figure>
        </>
    )

}

function Gallery(props) {
    const ref = useRef(null);
    const { appRef } = props;
    const [data, setData] = useState([]);

    //https://www.pluralsight.com/guides/fetch-data-from-a-json-file-in-a-react-app
    const getData = () => {
        fetch('gallery.json',
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                //console.log(response)
                return response.json();
            })
            .then(function (myJson) {
                //console.log(myJson.items);
                setData(myJson.items)
            });
    }

    function galleryHoverAnimation(event) {
        const eventType = event.type;
        const element = event.currentTarget;
        const image = element.querySelector('img');
        const link = element.querySelector('a');
        const caption = element.querySelector('figcaption');

        let imgBorderRadius = getComputedStyle(image).borderRadius;
        let image_tl = gsap.timeline()
            .set(link, {
                borderRadius: imgBorderRadius,
            })
            .to(image,
                {
                    scale: eventType === 'mouseenter' || eventType === 'touchstart' ? 1.1 : 1,
                    duration: 0.3
                }
            )
            .to(link,
                {
                    scale: eventType === 'mouseenter' || eventType === 'touchstart' ? 0.95 : 1,
                    duration: 0.3
                },
                "<"
            )

        if (window.matchMedia('(hover: hover)').matches) {
            image_tl.to(caption,
                {
                    y: eventType === 'mouseenter' ? "100%" : "0%",
                    opacity: eventType === 'mouseenter' ? 1 : 0,
                    duration: 0.3
                }
            )
        }


        return image_tl;
    }

    function handleGalleryHover(event) {
        galleryHoverAnimation(event);
    }

    function handleGalleryUnhover(event) {
        galleryHoverAnimation(event);
    }

    useEffect(() => {
        console.log('Gallery')
        const ctx = gsap.context(() => {

            getData();

            const element = ref.current;
            const root = appRef.current.closest("#root");

            let heading = element.querySelector(".title-block .heading");
            let gallery = element.querySelector(".gallery");

            explodeText(heading);

            let gallery_tl = gsap.timeline({
                scrollTrigger: {
                    //markers: true,
                    trigger: element,
                    start: "top 35%",
                    end: "bottom 60%",
                    pin: element.querySelector(".title-block"),
                    invalidateOnRefresh: true,
                }
            })

            gallery_tl.addLabel(`beforeHeadingShowed`);

            let letters = heading.querySelectorAll(".animation-item");

            for (let n = 0; n < letters.length; n += 1) {

                let index = getComputedStyle(letters[n]).getPropertyValue('--index');

                gallery_tl.fromTo(
                    letters[n],
                    {
                        y: "-20%",
                        opacity: 0,
                        rotate: "-20deg"
                    },
                    {
                        duration: 0.35,
                        ease: "power1.inOut",
                        y: "0%",
                        opacity: 1,
                        rotate: "0deg"
                    },
                    index === '0' ? `beforeHeadingShowed` : `>-=0.3`
                );

            }

            gallery_tl.addLabel(`afterHeadingShowed`);

            gallery_tl.fromTo(
                gallery,
                {
                    y: "5%",
                    opacity: 0,
                },
                {
                    duration: 0.35,
                    ease: "power1.inOut",
                    y: "0%",
                    opacity: 1,
                },
                `afterHeadingShowed`
            );

            const cursorEl = appRef.current.querySelector("#custom-cursor");

            Fancybox.bind(".gallery-block a", {
                groupAll: true, // Group all items
                parentEl: root,
                dragToClose: false,
                caption: function (fancybox, carousel, slide) {
                    let slideSrc = slide.src;
                    let clickedLink = appRef.current.querySelector(`.gallery-block .image > a[href="${slideSrc}"]`);
                    let caption = clickedLink.nextSibling.innerHTML;
                    return (
                        caption //slide.caption
                    );
                },
                on: {
                    destroy: (fancybox, slide) => {
                        new Cursor(cursorEl).leaveFullscreen();
                        new Cursor(cursorEl).leave();
                    },
                    done: (fancybox, slide) => {
                        root.querySelector('.fancybox__container').addEventListener('mouseover', (event) => {
                            if (event.target.tagName === 'IMG' ||
                                event.target.tagName === 'BUTTON' ||
                                event.target.classList.contains('fancybox__caption')) {
                                new Cursor(cursorEl).leaveFullscreen()
                                new Cursor(cursorEl).leave()
                            } else {
                                new Cursor(cursorEl).enterFullscreen()
                            }
                        });
                        gsap.set('.fancybox__container', {
                            top: Scrollbar.get(document.body)?.offset.y + 'px'
                        })
                    },
                },

                Toolbar: { // https://fancyapps.com/docs/ui/fancybox/plugins/toolbar
                    display: [],
                },
                Thumbs: {
                    autoStart: false,
                },
                Carousel: {
                    Navigation: {
                        prevTpl:
                            '<svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_465_202)"> <path d="M47.5986 0.205078C37.4062 1.18945 27.2959 5.35254 19.3799 11.792C17.042 13.6787 13.3506 17.4111 11.5049 19.7285C6.80859 25.6143 2.95312 33.5713 1.35352 40.708C0.348633 45.1787 0.123047 47.373 0.123047 52.5C0.123047 57.627 0.348633 59.8213 1.35352 64.292C2.97363 71.5723 6.93164 79.6523 11.792 85.6201C13.6787 87.958 17.4111 91.6494 19.7285 93.4951C25.6143 98.1914 33.5713 102.047 40.708 103.646C45.1787 104.651 47.373 104.877 52.5 104.877C57.627 104.877 59.8213 104.651 64.292 103.646C69.6445 102.457 76.6582 99.4629 81.2725 96.4072C89.25 91.0957 95.3818 84.123 99.5859 75.5713C102.149 70.3418 103.605 65.6455 104.528 59.6777C105.021 56.376 105.021 48.624 104.528 45.3223C103.605 39.3545 102.149 34.6582 99.5859 29.4287C96.8379 23.8506 93.7822 19.585 89.373 15.2373C85.0459 10.9512 81.0879 8.1416 75.5713 5.43457C70.4033 2.8916 65.6045 1.39453 59.8828 0.512695C57.2988 0.123047 50.2852 -0.0410156 47.5986 0.205078ZM53.5254 30.5771C54.9404 31.377 55.5352 33.2021 54.8789 34.7197C54.7148 35.0889 51.5156 38.4521 47.7012 42.2461L40.8105 49.1162L58.7344 49.2188C76.4531 49.3213 76.6787 49.3213 77.2324 49.752C78.3193 50.5518 78.6475 51.208 78.6475 52.5C78.6475 53.792 78.3193 54.4482 77.2324 55.248C76.6787 55.6787 76.4531 55.6787 58.7344 55.7812L40.8105 55.8838L47.7012 62.7539C51.5156 66.5479 54.7148 69.9111 54.8789 70.2803C56.0273 72.9258 53.4229 75.6533 50.7158 74.6484C49.957 74.3613 30.8027 55.248 30.4336 54.4072C30.1055 53.6279 30.0645 51.5361 30.3516 50.7158C30.6387 49.998 49.7109 30.8232 50.5518 30.4336C51.4541 30.0234 52.6436 30.085 53.5254 30.5771Z" fill="white"/> </g> <defs> <clipPath id="clip0_465_202"> <rect width="105" height="105" fill="white"/> </clipPath> </defs> </svg>',
                        nextTpl:
                            '<svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_465_203)"> <path d="M57.4014 0.205078C67.5938 1.18945 77.7041 5.35254 85.6201 11.792C87.958 13.6787 91.6494 17.4111 93.4951 19.7285C98.1914 25.6143 102.047 33.5713 103.646 40.708C104.651 45.1787 104.877 47.373 104.877 52.5C104.877 57.627 104.651 59.8213 103.646 64.292C102.026 71.5723 98.0684 79.6523 93.208 85.6201C91.3213 87.958 87.5889 91.6494 85.2715 93.4951C79.3857 98.1914 71.4287 102.047 64.292 103.646C59.8213 104.651 57.627 104.877 52.5 104.877C47.373 104.877 45.1787 104.651 40.708 103.646C35.3555 102.457 28.3418 99.4629 23.7275 96.4072C15.75 91.0957 9.61816 84.123 5.41406 75.5713C2.85059 70.3418 1.39453 65.6455 0.47168 59.6777C-0.0205078 56.376 -0.0205078 48.624 0.47168 45.3223C1.39453 39.3545 2.85059 34.6582 5.41406 29.4287C8.16211 23.8506 11.2178 19.585 15.627 15.2373C19.9541 10.9512 23.9121 8.1416 29.4287 5.43457C34.5967 2.8916 39.3955 1.39453 45.1172 0.512695C47.7012 0.123047 54.7148 -0.0410156 57.4014 0.205078ZM51.4746 30.5771C50.0596 31.377 49.4648 33.2021 50.1211 34.7197C50.2852 35.0889 53.4844 38.4521 57.2988 42.2461L64.1895 49.1162L46.2656 49.2188C28.5469 49.3213 28.3213 49.3213 27.7676 49.752C26.6807 50.5518 26.3525 51.208 26.3525 52.5C26.3525 53.792 26.6807 54.4482 27.7676 55.248C28.3213 55.6787 28.5469 55.6787 46.2656 55.7812L64.1895 55.8838L57.2988 62.7539C53.4844 66.5479 50.2852 69.9111 50.1211 70.2803C48.9727 72.9258 51.5771 75.6533 54.2842 74.6484C55.043 74.3613 74.1973 55.248 74.5664 54.4072C74.8945 53.6279 74.9355 51.5361 74.6484 50.7158C74.3613 49.998 55.2891 30.8232 54.4482 30.4336C53.5459 30.0234 52.3564 30.085 51.4746 30.5771Z" fill="white"/> </g> <defs> <clipPath id="clip0_465_203"> <rect width="105" height="105" fill="white" transform="matrix(-1 0 0 1 105 0)"/> </clipPath> </defs> </svg>',
                    },
                },
            });


        });

        return () => ctx.revert();

    }, [])

    return (
        <section className="gallery-block color-white mb-1" id="gallery" ref={ref} data-bgcolor="#141E30">
            <div id="gallery-trigger" className='trigger'></div>
            <div className="title-block">
                <h2 className="heading">Галерея</h2>
            </div>
            <div className="gallery gallery_random color-white mb-2">
                {
                    data && data.length > 0 &&
                    data.map(item => (
                        <GalleryItem data={item} key={Math.random()} handleGalleryHover={handleGalleryHover} handleGalleryUnhover={handleGalleryUnhover} />
                    ))
                }
            </div>
        </section>
    )
}

export { Gallery };